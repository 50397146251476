import styled from "styled-components";

export const InputContainer = styled.div`
    input{
        width: 100%;
        padding: 10px;
        border: none;
        margin-top: 3px;
        background: ${props => props.theme.primary.secundary};
        border: 1px solid black;
    }
`;