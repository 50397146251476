import React, { useContext } from 'react';
import Pagination from '../Pagination';
import Button from '../Button';
import RoundedButton from '../TableRoundedButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddSection from '../AddSection';
import TableFilter from '../TableFilter';
import EditSection from '../EditSection';

import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

import { TableContainer, TableObject, TableHeader, TableRow, TableHead, TableBody, TableData, TableSettings, TableSettingsActions, TableDataContentHolder } from './styles';

function KeywordTable({ tableHeader, tableRows, page, setPage, totalPages, setRefresh, handle_delete, setQuery }) {
    
    const { user } = useContext(GeneralContext)
    
    return(
        <TableContainer>
            <TableSettings>
                <Pagination page={page} setPage={setPage} totalPages={totalPages} />
                <TableSettingsActions>
                    { 
                        user.role !== 'viewer' 
                        ? <AddSection setRefresh={setRefresh} /> 
                        : null 
                    }
                    <TableFilter setQuery={setQuery} />
                    <Button width='150px' margin='0 .5rem 0 .5rem' onClick={() => setRefresh(Math.random())}>Limpar Filtro</Button>
                </TableSettingsActions>
            </TableSettings>
            <TableObject>
                <TableHeader>
                    <TableRow>
                        {
                            !!tableHeader && tableHeader.length > 0
                            ? tableHeader.map((item, index) => (
                                <TableHead key={index}>{item}</TableHead>
                            ))
                            : null
                        }
                    </TableRow>
                </TableHeader>
                <TableBody>
                        {
                            !!tableRows && tableRows.length > 0
                            ? tableRows.map((item, index) => (
                                <TableRow key={index}>
                                    <TableData>{((page * 10) - 10) + index}</TableData>
                                    <TableData title={item}><span>{item}</span></TableData>
                                    { 
                                        user.role !== 'viewer' 
                                        ?   <TableData>
                                                <TableDataContentHolder>
                                                    <RoundedButton onClick={() => handle_delete(item)}><DeleteIcon /></RoundedButton>
                                                    <EditSection setRefresh={setRefresh} rowData={item} />
                                                </TableDataContentHolder>
                                            </TableData>
                                        :   null
                                    } 
                                </TableRow>
                            ))
                            : null
                        }
                </TableBody>
            </TableObject>
        </TableContainer>
    );
}

export default KeywordTable;