import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import { TagContainer, TagHeader, TagTitle, TagHolder, Tag } from './styles';

function TagBox({ words, setWords }) {

    const handle_remove = async (index) => {
        let newWords = words.slice();
        newWords.splice(index, 1)
        setWords(newWords);
    }

    const clear_all = () => {
        setWords(words => words.length > 0 ? [] : words);
    }

    return(
        <TagContainer>
            <TagHeader>
                <TagTitle>Fila de adição</TagTitle>
                <IconButton size='small' onClick={clear_all} ><ClearAllIcon /></IconButton>
            </TagHeader>
            <TagHolder>
                { 
                    !!words
                    ? words.map((item, index) => (
                        <Tag>{item}<HighlightOffIcon style={{ cursor: 'pointer' }} onClick={() => handle_remove(index)}/></Tag>
                    ))
                    : null
                }
            </TagHolder>
        </TagContainer>
    );
}

export default TagBox;