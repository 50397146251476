export default {
    primary: {
        primary: '#2B343B',
        secundary: '#1E272E',
        complementary: '#455CC7',
        hover: '#22292E',
        text: '#F1F1F1',
        buttons: {
            hover: '#203EC9',
            accept: '#548FFF'
        }
    }
};