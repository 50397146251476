import React, { useContext } from 'react';
import Chart from "react-apexcharts";

import { ThemeContext } from 'styled-components';

import { TimeContainer } from './styles';

function TimeChart({ data, name }) {

    const { primary } = useContext(ThemeContext); 

    return(
        <TimeContainer>
            <Chart
                options={{
                    title: {
                        text: name,
                        align: 'left',
                        style:{
                            fontFamily: 'Roboto',
                            color: primary.text,
                        }
                    },
                    chart: {
                        id: "line"   
                    },
                    stroke:{
                        curve: "smooth",
                        width: 1.5,
                        lineCap: 'butt',
                    },
                    xaxis: { 
                        tickAmount: 1,
                        type: 'datetime',
                        categories: Object.keys(data).map(item => new Date(item).getTime()),
                        labels: {
                            datetimeUTC: false,
                            style: {
                                colors: primary.text
                            }
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: primary.text
                            }
                        }
                    },
                    grid: {
                        borderColor: "#e0e0e01d"
                    }
                  }}
                series={[
                    {
                        name: "Infrações",
                        data: Object.values(data)
                    }
                ]}
                type="line"
            />
        </TimeContainer>
    );
}

export default TimeChart;