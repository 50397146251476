import React, { useContext } from 'react';
import Chart from "react-apexcharts";

import { ThemeContext } from 'styled-components';

import { BubbleContainer } from './styles';

function BubbleChart({ data, name }) {

    const { primary } = useContext(ThemeContext);

    return(
        <BubbleContainer>
            <Chart
                options={{
                    chart: {
                        type: 'bubble',
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        opacity: 0.8
                    },
                    title: {
                        text: name,
                        align: 'left',
                        style:{
                            fontFamily: 'Roboto',
                            color: primary.text,
                        }
                    },
                    legend:{
                        show: false
                    },
                    tooltip:{
                        custom: function({ series, seriesIndex, dataPointIndex, w }) {
                            return (
                              `<div class="tooltipContainer">
                                    <div class="tooltipHeader">${w.globals.seriesNames[seriesIndex]}</div>
                                    <div class="tooltipBody">
                                        Pessoas alcançadas: <strong>${w.globals.seriesX[seriesIndex][0]}</strong><br/>
                                        Quantidade de infrações: <strong>${series[seriesIndex]}</strong>
                                    </div>
                                </div>`
                            );
                          }
                    },
                    xaxis: {
                        tickAmount: 2,
                        type: 'numeric',
                        labels: {
                            style: {
                                colors: primary.text
                            }
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: primary.text
                            }
                        },
                    },
                    grid: {
                        borderColor: "#e0e0e01d"
                    }
                }}
                series={data}
                type="bubble"
            />
        </BubbleContainer>
    );
}

export default BubbleChart;