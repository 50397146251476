import React, { useState } from 'react';
import { SnackbarProvider } from 'notistack';

import GeneralContext from './GeneralContext';

function GeneralContextProvider({ children }) {

    const [ loading, setLoading ] = useState(false);
    const [ user, setUser ] = useState({});

    return(
        <SnackbarProvider maxSnack={3}>
            <GeneralContext.Provider value={{
                loading, setLoading,
                user, setUser
            }}>
                {children}
            </GeneralContext.Provider>
        </SnackbarProvider>
    );
}

export default GeneralContextProvider;