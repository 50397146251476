import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BarChartIcon from '@material-ui/icons/BarChart';
import Settings from '@material-ui/icons/Settings';
import Button from '../Button';
import Alest from '../Alest';
import Cookies from 'js-cookie';
import api from '../../services/api';

import AuthContext from '../../contexts/AuthContext/AuthContext';
import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

import { AsideContainer, AsideUnorderedList, AsideLink, AsideUnorderedListHeader, PhotoHolder, Image, WelcomeHolder, AlestLogoHolder, UserHolder } from './styles';

function Aside() {

  const token = Cookies.get('@user');

  const history = useHistory();

  const { auth, setAuth } = useContext(AuthContext);

  const { user, setUser } = useContext(GeneralContext);

  useEffect(() => {
    api('user', { 
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if(res.status >= 200 && res.status < 300){
        const data = await res.json()
        setUser(data.response);
      }
    })
  }, [setUser, token])

  useEffect(() => {
    if(auth === false){
      history.push('/');
    }
  }, [ auth ])

  const logout = () => {
    Cookies.remove('@user');
    setAuth(false);
  }

  return(
    <AsideContainer>
        <AsideUnorderedList>
          <AsideUnorderedListHeader>
            <AlestLogoHolder>
              <Alest />
            </AlestLogoHolder>
            <UserHolder>
              <PhotoHolder>
                <Image src={user.photoURL} />
              </PhotoHolder>
              <WelcomeHolder>
                <p>Bem Vindo,</p>
                <p>{user.displayName}</p>
              </WelcomeHolder>
            </UserHolder>
          </AsideUnorderedListHeader>
          <Link to='/home'><AsideLink><HomeIcon fontSize='inherit' />Início</AsideLink></Link>
          <Link to='/keywords'><AsideLink><VpnKeyIcon fontSize='inherit' />Palavras-chave</AsideLink></Link>
          <Link to='/report'><AsideLink><BarChartIcon fontSize='inherit' />Relatórios</AsideLink></Link>
          <Link to='/settings'><AsideLink><Settings fontSize='inherit' />Configurações</AsideLink></Link>
        </AsideUnorderedList>
        <AsideUnorderedList>
          <AsideLink><Button onClick={logout} width='100%' margin='0px 0px 0px 0px'>Sair</Button></AsideLink>
        </AsideUnorderedList>
    </AsideContainer>
  );
}

export default Aside;