import React, { useState } from 'react';

import { SettingsContainer } from './styles';
import SettingsAside from '../../components/SettingsAside';
import ProfileSettings from '../../components/ProfileSettings';
import AddUser from '../../components/AddUser';
import ManageUser from '../../components/UserList';

function Report() {

    const [ page, setPage ] = useState('profile')

    const Content = () => {
        if(page === 'profile') return <ProfileSettings />
        if(page === 'addUser') return <AddUser />
        if(page === 'manageUser') return <ManageUser />
    }

    return(
        <SettingsContainer>
            <SettingsAside setPage={setPage}/>
            <Content />
        </SettingsContainer>
    );
}

export default Report;