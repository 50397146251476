import React, { useState } from 'react';
import { addDays } from 'date-fns';
import { DateRange } from 'react-date-range';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Button from '../Button';
import RoundedButton from '../TableRoundedButton';
import CheckIcon from '@material-ui/icons/Check';

import { DateContainer, CalendarHolder, ActionHolder } from './styles';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

    
function DateRangePicker({ setQuery, query }) {
    
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    const [ checked, setChecked ] = useState(false);

    const handle_check = () => {
        setChecked(true);
    }
    
    const handle_clickaway = () => {
        setChecked(false);
    }

    const handle_submit = () => {
        
        let initial = state[state.length - 1].startDate.toISOString()
        let final = state[state.length - 1].endDate.toISOString()

        const new_query = `created_time BETWEEN "${initial}" AND "${final}"`

        setQuery({ ...query, date: new_query });

    }

    return(
        <ClickAwayListener onClickAway={handle_clickaway}>
            <DateContainer>
                <Button width='150px' margin='0 .5rem 0 .5rem' onClick={handle_check}>Selecionar Período</Button>
                <Grow in={checked} style={{ transformOrigin: '0 0 0' }} {...(checked ? { timeout: 200 } : {})}>
                    <CalendarHolder>
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => setState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                        />
                        <ActionHolder>
                            <RoundedButton style={{'marginLeft': 'auto'}} onClick={handle_submit}>
                                <CheckIcon />
                            </RoundedButton>
                        </ActionHolder>
                    </CalendarHolder>
                </Grow>
            </DateContainer>
        </ClickAwayListener>
    );
}

export default DateRangePicker;