import React, { useState, useEffect, useContext } from 'react';
import Card from '../../components/Card';
import TimeChart from '../../components/TimeChart'
import BubbleChart from '../../components/BubbleChart';
import ReportTable from '../../components/ReportTable';
import BarChart from '../../components/BarChart';
import api from '../../services/api';
import Cookies from 'js-cookie';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import NamePicker from '../../components/NamePicker';
import DatePicker from '../../components/DateRange';
import GeneralContext from '../../contexts/GeneralContext/GeneralContext';
import Button from '../../components/Button';

import { ReportContainer, PageTitle, ReportHolder, ChartHolder, ReportHeader, TableRow, TableData, ChartRow, CardTitle, CardContent } from './styles';

function Report() {

    const { setLoading } = useContext(GeneralContext);

    const token = Cookies.get("@user");

    const [ fastRefresh, setFastRefresh ] = useState();
    const [ infrationContent, setInfrationContent ] = useState([]);
    const [ pageTokens, setPageTokens ] = useState({ 1: undefined });
    const [ page, setPage ] = useState(1);
    const [ results, setResults ] = useState(0);
    const [ bubbleData, setBubbleData ] = useState([]);
    const [ localData, setLocalData ] = useState({});
    const [ topData, setTopData ] = useState({});
    const [ totalsData, setTotalsData ] = useState({});
    const [ timeData, setTimeData ] = useState({});

    const [ query, setQuery ] = useState({});

    useEffect(() => {
        setLoading(true);
        setPageTokens({ 1: undefined })
        api(`report/totalPerUser`, {
            method: 'GET',
            headers: { 
                Authorization: `Bearer ${token}`,
                Query: JSON.stringify(query) 
            }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                setBubbleData(data.response);
            }
        });
        api(`report/totalPerType`, {
            method: 'GET',
            headers: { 
                Authorization: `Bearer ${token}`,
                Query: JSON.stringify(query)
            }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                setTotalsData(data.response[0]);
            }
        });
        api(`report/table`, {
            method: 'GET',
            headers: { 
                Authorization: `Bearer ${token}`,
                Query: JSON.stringify(query),
                Token: JSON.stringify(pageTokens[page])
            }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                setInfrationContent(data.response.rows);
                setResults(data.response.results);
                const key = page + 1;
                if(pageTokens[key] === undefined){
                    let newData = { ...pageTokens };
                    newData[key] = data.response.pageToken;
                    setPageTokens(newData);
                }
            }
        });
        api(`report/getTimeEvents`, {
            method: 'GET',
            headers: { 
                Authorization: `Bearer ${token}`,
                Query: JSON.stringify(query) 
            }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                setTimeData(data.response);
            }
        });
        api(`report/totalPerLocal`, {
            method: 'GET',
            headers: { 
                Authorization: `Bearer ${token}`,
                Query: JSON.stringify(query) 
            }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                setLocalData(data.response[0]);
            }
        });
        api(`report/topUser`, {
            method: 'GET',
            headers: { 
                Authorization: `Bearer ${token}`,
                Query: JSON.stringify(query) 
            }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                setTopData(data.response);
            }
            setLoading(false);
        });
    }, [ fastRefresh, setLoading, token, query, page ])

    const TableRows = () => {
        return(
            <>
                {
                    infrationContent.length > 0
                    ? infrationContent.map((item, index) => (
                        <TableRow key={index}>
                            <TableData>{item.created_time.value}</TableData>
                            <TableData>{item.user_name}</TableData>
                            <TableData title={item.content}><span>{item.content}</span></TableData>
                            <TableData>{item.violation_quote}</TableData>
                            <TableData>{item.file_type}</TableData>
                            <TableData>{item.violation_type}</TableData>
                            <TableData>{item.local}</TableData>
                        </TableRow>
                    ))
                    : null
                }
            </>
        )
    }

    return(
        <ReportContainer>
            <PageTitle>Relatórios » Visão geral da comunidade - 
                <IconButton color='inherit' size='small' onClick={() => setFastRefresh(Math.random())}><RefreshIcon fontSize='large'/></IconButton></PageTitle>
            <ReportHolder>
                <ReportHeader>
                    <DatePicker query={query} setQuery={setQuery}/>
                    <NamePicker query={query} setQuery={setQuery}/>
                    <Button width='150px' margin='0 .5rem 0 .5rem' onClick={() => setQuery([])}>Limpar Filtro</Button>
                </ReportHeader>
                <ChartHolder>
                    <ChartRow>
                        <Card width={'20%'}>
                            <CardTitle>Palavras-chave bloqueadas</CardTitle>
                            <CardContent>{totalsData.badword || 0}</CardContent>
                        </Card>
                        <Card width={'20%'}>
                            <CardTitle>CPFs bloqueados</CardTitle>
                            <CardContent>{totalsData.cpf || 0}</CardContent>
                        </Card>
                        <Card width={'20%'}>
                            <CardTitle>RGs bloqueados</CardTitle>
                            <CardContent>{totalsData.rg || 0}</CardContent>
                        </Card>
                        <Card width={'20%'}>
                            <CardTitle>Cartões bloqueados</CardTitle>
                            <CardContent>{totalsData.credit_card || 0}</CardContent>
                        </Card>
                        <Card width={'20%'}>
                            <CardTitle>Conteúdos adulto bloqueados</CardTitle>
                            <CardContent>{totalsData.adult_content || 0}</CardContent>
                        </Card>
                    </ChartRow>
                    <ChartRow>
                        <Card width={'50%'}>
                            <TimeChart data={timeData} name="Infrações temporais"/>
                        </Card>
                        <Card width={'50%'}>
                            <BubbleChart data={bubbleData} name='Pessoas atingidas por infrações' />
                        </Card>
                    </ChartRow>
                    <ChartRow>
                        <Card width={'100%'}>
                            <ReportTable 
                                tableHeader={[ 'Data', 'Usuário', 'Conteúdo', 'Infração', 'Tipo do arquivo', 'Tipo de infração', 'Local' ]}
                                TableRows={TableRows}
                                page={page}
                                setPage={setPage}
                                results={results}
                            />
                        </Card>
                    </ChartRow>
                    <ChartRow>
                        <Card width={'50%'}>
                            <BarChart dados={localData} name="Locais com maiores números de infrações" />
                        </Card>
                        <Card width={'50%'}>
                            <BarChart dados={topData} name="Top 5 maiores infratores da comunidade" />
                        </Card>
                    </ChartRow>
                </ChartHolder>
            </ReportHolder>
        </ReportContainer>
    );
}

export default Report;