import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { InputContainer } from './styles';

export default function SelectInput({ name, width, children, ...rest }) {

    const inputRef = useRef(null);

    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
        registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        });
    }, [fieldName, registerField]);

    return(
        <InputContainer>
            <select style={{ width }} ref={inputRef} {...rest}>
                {children}
            </select>
        </InputContainer>
    );
}