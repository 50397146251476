import React, { useContext } from 'react';
import LockerAnimation from '../../components/LockerAnimation'
import Button from '../../components/Button';
import VideoTutorial from '../../components/VideoTutorial';

import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

import { 
    HomeContainer, 
    WelcomeUser, 
    UserTextHolder, 
    UserParagraph,
    PageTitle
} from './styles';


function Home() {
    
    const { user } = useContext(GeneralContext);

    return(
        <HomeContainer>
            <PageTitle>Início</PageTitle>
            {/* <VideoTutorial /> */}
            <WelcomeUser>
            Bem vindo, <strong>{user.displayName || 'Novo usuário'}!</strong>
            </WelcomeUser>
            <UserTextHolder>
                <LockerAnimation />
                <UserParagraph>
                    Com a revolução tecnológica em curso, as ferramentas de comunicação ganharam um papel fundamental para a melhoria da produtividade nos ambientes corporativos. Esta constante troca de informações entre os colaboradores tornou-se vital para qualquer negócio. Porém, os dados sensíveis constituem um importante ativo das corporações e precisam ser protegidos, evitando problemas mais críticos como um vazamento de dados sensíveis.
                </UserParagraph>
                <UserParagraph>
                    A Solução de DLP, desenvolvida pela Alest Consultoria, escaneia os dados sensíveis localizados em textos e imagens.
                </UserParagraph>
                <UserParagraph>
                    Criado para melhoria do gerenciamento de dados confidenciais. O Data Loss Prevention (DLP) identifica padrões através de detectores pré-definidos, formatos, somas e até mesmo compreende dicas contextuais, como classificação e edição rápida de elementos de dados confidenciais. O objetivo da solução é identificar os dados sensíveis através dos padrões configurados e realizar ações preventivas.
                </UserParagraph>
                <UserParagraph>
                    Este é o painel de gerenciamento e visualização de infrações capturadas pelo Data Loss Prevent no Workplace.
                </UserParagraph>
                <UserParagraph>
                    Neste você é capaz de adicionar ou remover palavras proibidas, visualizar métricas de ação do bot e insights sobre infrações para otimizar a tomada de decisões acerca de possíveis problemas.
                </UserParagraph>
                {/* <Button width='60%' margin='2rem auto'>Como utilizar a plataforma?</Button> */}
            </UserTextHolder>
        </HomeContainer>
    );
}

export default Home;