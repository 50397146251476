import React from 'react';
import Lottie from 'lottie-react-web';
import loginAnimation from '../../static/lottie/loginAnimation.json';

import { LoginAnimationContainer } from './styles';

function LoginAnimation() {
  return(
    <LoginAnimationContainer>
      <Lottie 
        options={{
          animationData: loginAnimation,
        }}
        width='150%'
      />
    </LoginAnimationContainer>
  );
}

export default LoginAnimation;