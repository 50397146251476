import styled from 'styled-components';

export const AsideContainer = styled.aside`
    width: 25rem;
    height: 100%;
    background: ${props => props.theme.primary.primary}3d;
`

export const UnorderedList = styled.ul`
    width: 100%;
    list-style: none;
    padding: 20px 0;
`;

export const ListLink = styled.li`
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    svg{
        margin-right: 3rem;
    }

    :hover{
        background: ${props => props.theme.primary.secundary};
        cursor: pointer;
    }
`;