import React, { useState, useEffect, useRef } from 'react';
import Button from '../Button';
import FilterInput from '../FilterInput';

import { TableFilterContainer } from './styles';
import { Form } from '@unform/core';

function TableFilter({ setQuery }) {

    const [ toggleFilter, setToggleFilter ] = useState(false);

    const formRef = useRef(null);

    const handle_toggle_filter = () => {
        setToggleFilter(!toggleFilter);
    }

    const handle_submit = ({ query }, { reset }) => {
        setQuery(query);
        reset();
    }

    useEffect(() => {
        document.getElementById('enter').addEventListener('keypress', function(event) {
            if (event.keyCode === 13) {
                formRef.current.submitForm();
            }
        });
    }, [])

    return(
        <TableFilterContainer>
            <Form ref={formRef} onSubmit={handle_submit}>
                <FilterInput 
                    id='enter' 
                    style={{ width: !!toggleFilter ? '200px' : '0px' }} 
                    name='query' 
                    type='text' 
                    placeholder='Aperte Enter para aplicar' 
                    required
                />
            </Form>
                <Button width='150px' margin='0 .5rem 0 .5rem' onClick={handle_toggle_filter}>Filtro</Button>
        </TableFilterContainer>
    );
}

export default TableFilter;