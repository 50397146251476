import styled from "styled-components";

export const InputContainer = styled.div`
    input{
        padding: 10px;
        border: none;
        background: ${props => props.theme.primary.secundary};
        border-radius: 20px;
        width: 100%;
        border: 1px solid #3333331d;
    }
`;