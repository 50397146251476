import styled from "styled-components";

export const ProfileInformation = styled.div`
    width: 70rem;
    background: ${props => props.theme.primary.primary};
    padding: 10px 20px;
    border-radius: 5px;
    
    form{
        display: flex;
        font-size: 1.4rem;
    }
`;

export const ProfilePhotoHolder = styled.div`
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background: ${props => props.theme.primary.primary};
    overflow: hidden;
    border: 2px solid ${props => props.theme.primary.secundary};
`;

export const ProfilePhotoContainer = styled.div`
    min-height: 100%;
    width: 15%;
    margin-right: 2rem;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
`;

export const ProfilePhoto = styled.img`
    width: 100%;
    height: 100%;
`;

export const InformationContainer = styled.div`
    width: 85%;
`;

export const InformationTitle = styled.h2`
    color: ${props => props.theme.primary.text}1d;
    font-size: inherit;
    margin-bottom: .5rem;
`;

export const InformationSection = styled.div`
    margin: 2rem 0;
`;

export const ChangeContainer = styled.div`
    padding: 1rem 0;
    width: 100%;
    border-top: 1px solid ${props => props.theme.primary.text}1d;
`;

export const ChangeHolder = styled.div`
    margin-left: auto;
    width: 35px;
`;

