import React, { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import LoginAnimation from '../../components/LoginAnimation';
import LoginBox from '../../components/LoginBox';
import Loader from '../../components/Loader';
import AlestLogo from '../../components/Alest';

//styles
import { LoginContainer, LeftContainer, RightContainer, MiddleContainer, WelcomeTitle, Background, Logo } from './styles';
import GlobalStyle from '../../static/global_css';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext/AuthContext';

function Login() {

  const { auth, setAuth } = useContext(AuthContext);

  const history = useHistory();

  useEffect(() => {
    if(auth === true) history.push('/home')
  }, [ auth ])

  if(Cookies.get('@user') !== undefined){
    setAuth(true);
  }

  const go_to_alest = () => {
    return window.open('https://www.alest.com.br/', '_blank');
  }

  return(
    <LoginContainer>
        <GlobalStyle />
        <Loader />
        <Background />
        <Logo onClick={go_to_alest}>
          <AlestLogo />
        </Logo>
        <MiddleContainer>
            <LoginBox />
        </MiddleContainer>
        <LeftContainer>
            <WelcomeTitle>Bem vindo ao<br /><strong>Data Loss Prevention</strong></WelcomeTitle>
        </LeftContainer>
        <RightContainer>
            <LoginAnimation />
        </RightContainer>
    </LoginContainer>
  ); 
}

export default Login;