import styled from 'styled-components';

export const LoginContainer = styled.div`
    width: 35%;
    max-width: 450px;
    height: 50%;
    background: ${props => props.theme.primary.primary};
    border-radius: 10px;
    box-shadow: 0 5px 8px #0000002d;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    input{
        background: ${props => props.theme.primary.secundary};
    }
`;

export const LoginTitle = styled.div`
    width: 100%;
    font-size: 2em;
    text-align: center;
    margin-bottom: 5rem;
`;
