import styled from "styled-components";

export const KeywordsContainer = styled.div`
    width: 90%;
    height: 100%;
    padding: 8rem 2rem;
    overflow: auto;
`;

export const PageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 5rem;
`;