import styled from "styled-components";

export const BarContainer = styled.div`

    *:before, *:after, *{
        color: ${props => props.theme.primary.primary};
    }

    .tooltipHeader{
        width: 100%;
        background: #ECEFF1;
        padding: .5rem;
        border-bottom: 1px solid #0000001d;
        font-size: 1.2rem;
    }

    .tooltipBody{
        padding: 1rem;
        font-size: 1.2rem;
    }
`;
