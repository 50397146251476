import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import api from '../../services/api';
import Cookies from 'js-cookie';
import Table from '../ReportTable';
import RoundedButton from '../TableRoundedButton';
import DeleteIcon from '@material-ui/icons/Delete';

import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

import { 
    ProfileContainer, 
    PageTitle, 
    ProfileInformation, 
    InformationContainer,
    TableRow,
    TableData
} from './styles';


function UserList() {

    const [ pageTokens, setPageTokens ] = useState({ 1: undefined });
    const [ page, setPage ] = useState(1);
    const [ rows, setRows ] = useState([]);
    const [ totalUsers, setTotalUsers ] = useState(0);

    const { user } = useContext(GeneralContext);

    const token = Cookies.get('@user');

    const { setLoading } = useContext(GeneralContext);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true)
        api(`user/list?${ pageTokens[page] !== undefined ? 'pageToken=' : '' }${ pageTokens[page] }`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                setRows(data.response.users)
                setTotalUsers(data.response.pages)
                const key = page + 1;
                if(pageTokens[key] === undefined){
                    let newData = { ...pageTokens };
                    newData[key] = data.response.pageToken;
                    setPageTokens(newData);
                }
            }
            setLoading(false)
        })
    }, [ page ])

    const handle_delete = async (uid) => {
        setLoading(true)
        api('user', {
            method: 'DELETE',
            body: JSON.stringify({ uid }),
            headers: { 
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json' 
            }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                enqueueSnackbar('Usuário removido com sucesso.', { variant: "success" })
                setLoading(false)
            }else{
                enqueueSnackbar(data.response, { variant: "error" })
                setLoading(false)
            }
        })
    }

    const TableRows = () => {
        return(
            <>
                {
                    rows.length > 0
                    ? rows.map((item, index) => (
                        <TableRow key={index}>
                            <TableData>{index}</TableData>
                            <TableData title={item.uid}><span>{item.uid}</span></TableData>
                            <TableData title={item.displayName}><span>{item.displayName}</span></TableData>
                            <TableData title={item.email}><span>{item.email}</span></TableData>
                            <TableData title={item.role}><span>{item.role}</span></TableData>
                            <TableData>
                                <RoundedButton disabled={user.role === 'admin' && item.role === 'Proprietário'} onClick={() => handle_delete(item.uid)}>
                                    <DeleteIcon />
                                </RoundedButton>
                            </TableData>
                        </TableRow>
                    ))
                    : null
                }
            </>
        )
    }

    return(
        <ProfileContainer>
            <PageTitle>Gerenciar Usuários</PageTitle>
            <ProfileInformation>
                <InformationContainer>
                    <Table 
                        page={page}
                        setPage={setPage}
                        results={totalUsers} 
                        tableHeader={[ 'Índice', 'ID', 'Nome', 'Email', 'Cargo', 'Ações' ]} 
                        TableRows={TableRows}
                    />
                </InformationContainer>
            </ProfileInformation>
        </ProfileContainer>
    );
}

export default UserList;