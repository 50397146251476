import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';

import AuthProvider from './contexts/AuthContext/AuthContextProvider';

ReactDOM.render(
    <AuthProvider>
      <Routes />
    </AuthProvider>,
  document.getElementById('root')
);