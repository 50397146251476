import React, { useContext } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import '../services/config.js';

//contexts
import { ThemeProvider } from 'styled-components';
import GeneralContextProvider from '../contexts/GeneralContext/GeneralContextProvider';
import AuthContext from '../contexts/AuthContext/AuthContext';

//styles
import theme from '../static/theme';

//pages
import LoginPage from '../pages/Login';
import PanelPage from '../pages/Panel';

//subPages
import HomeContent from '../sub_pages/Home';
import KeywordsContent from '../sub_pages/Keywords';
import ReportContent from '../sub_pages/Report';
import SettingsContent from '../sub_pages/Settings';

export default function routes() {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { auth } = useContext(AuthContext);

  const ProtectedRoute = ({ component: Component, path: Path, content, ...rest }) => {
    return(
      <Route Path render={props => (
        auth === true
        ? <GeneralContextProvider><ThemeProvider theme={theme}><Component Content={content} {...props} {...rest} /></ThemeProvider></GeneralContextProvider>
        : <Redirect to={'/'} />
      )}/>
    );
  }

  return(
    <Router>
      <Switch>
        
        <Route path='/' exact>
          <GeneralContextProvider>
            <ThemeProvider theme={theme}>
              <LoginPage />
            </ThemeProvider>
          </GeneralContextProvider>
        </Route>

        <ProtectedRoute 
          path='/home'
          component={PanelPage}
          content={HomeContent}
        />
        <ProtectedRoute 
          path='/keywords'
          component={PanelPage}
          content={KeywordsContent}
        />
        <ProtectedRoute 
          path='/report'
          component={PanelPage}
          content={ReportContent}
        />
        <ProtectedRoute 
          path='/settings'
          component={PanelPage}
          content={SettingsContent}
        />

      </Switch>
    </Router>
  );
}