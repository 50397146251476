import styled from "styled-components";

export const LoginContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const Background = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: ${props => props.theme.primary.secundary};
`;

export const Logo = styled.nav`
    width: 100%;
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;

    svg:hover{
        fill: #DEE1E6;
        cursor: pointer;
    }
`;

export const LeftContainer = styled.div`
    width: 50%;
    height: 100%;
    position: relative;
    z-index: 2;
    border-top-right-radius: 30%;
    border-bottom-right-radius: 30%;
    background: ${props => props.theme.primary.complementary};
    display: flex;
    align-items: center;
    padding: 10px 260px;
    text-align: center;
    transition: 200ms ease-in;
    
    :hover{
        background: ${props => props.theme.primary.complementary}1d;
    }
`;

export const RightContainer = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
    z-index: 2;
    background: ${props => props.theme.primary.secundary};
`;

export const WelcomeTitle = styled.h1`
    font-weight: 300;
    font-size: 1.2rem;
    font-size: 2.5rem;
`;

export const MiddleContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
`;