import React, { useRef, useContext } from 'react';
import api from '../../services/api';
import Input from '../ProfileInput';
import Select from '../SelectInput';
import { Form } from '@unform/web';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import RoundedButton from '../TableRoundedButton';
import ImageInput from '../ImageInput';
import AddIcon from '@material-ui/icons/Add'

import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

import { 
    ProfileContainer, 
    PageTitle, 
    ProfileInformation, 
    ProfilePhotoContainer, 
    InformationContainer,
    InformationSection,
    InformationTitle,
    ChangeContainer,
    ChangeHolder
} from './styles';


function AddUser() {

    const { user } = useContext(GeneralContext);

    const formRef = useRef(null);

    const token = Cookies.get('@user');

    const { setLoading } = useContext(GeneralContext);
    const { enqueueSnackbar } = useSnackbar();

    const submit_form = () => {
        formRef.current.submitForm();
    }

    const handle_submit = (payload) => {
        setLoading(true);
        if(payload.password === payload.confirm_password){
            let formData = new FormData();
            formData.append('display_name', payload.display_name)
            formData.append('email', payload.email)
            formData.append('password', payload.password)
            formData.append('role', payload.role)
            formData.append('photo_url', payload.photo_url)
            api('user', {
                method: 'POST',
                body: formData,
                headers: { 
                    Authorization: `Bearer ${token}`
                }
            }).then(async (res) => {
                const data = await res.json()
                if(res.status >= 200 && res.status < 300){
                    enqueueSnackbar(data.response, { variant: 'success' });
                    setLoading(false);
                }else{
                    enqueueSnackbar(data.response, { variant: 'error' });
                    setLoading(false);
                }
            })
        }else{
            enqueueSnackbar('A senha é diferente da senha de verificação.', { variant: 'warning' })
            setLoading(false);
        }
    }

    return(
        <ProfileContainer>
            <PageTitle>Adicionar usuários</PageTitle>
            <ProfileInformation>
                <Form onSubmit={handle_submit} ref={formRef}>
                    <ProfilePhotoContainer>
                        <ImageInput name='photo_url' />
                    </ProfilePhotoContainer>
                    <InformationContainer>
                        <InformationSection>
                            <InformationTitle>NOME DE USUÁRIO</InformationTitle>
                            <Input name='display_name' />
                        </InformationSection>
                        <InformationSection>
                            <InformationTitle>EMAIL</InformationTitle>
                            <Input type='email' name='email' />
                        </InformationSection>
                        <InformationSection>
                            <InformationTitle>SENHA</InformationTitle>
                            <Input type='password' name='password' />
                            <Input type='password' name='confirm_password' />
                        </InformationSection>
                        <InformationSection>
                            <InformationTitle>CARGO</InformationTitle>
                            <Select name='role'>
                                { 
                                    user.role === 'owner' ?<option value='owner'>Proprietário</option> : null 
                                }
                                <option value='admin'>Administrador</option>
                                <option value='viewer'>Visualizador</option>
                            </Select>
                        </InformationSection>
                    </InformationContainer>
                </Form>
                <ChangeContainer>
                    <ChangeHolder>
                        <RoundedButton onClick={submit_form}><AddIcon /></RoundedButton>
                    </ChangeHolder>
                </ChangeContainer>
            </ProfileInformation>
        </ProfileContainer>
    );
}

export default AddUser;