import React, { useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import { LoaderContainer } from './styles';
import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

export default function Loader() {

    const { loading } = useContext(GeneralContext);

    return (
        <LoaderContainer style={{ opacity: loading ? 1 : 0}} >
            <LinearProgress />
        </LoaderContainer>
    );
}