import React, { useContext } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PeopleIcon from '@material-ui/icons/People';
import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

import { AsideContainer, UnorderedList, ListLink } from './styles';

function SettingsAside({ setPage }) {

    const { user } = useContext(GeneralContext);

    return(
        <AsideContainer>
            <UnorderedList>
                <ListLink onClick={() => setPage('profile')}>
                    <PersonIcon fontSize='inherit' />Perfil
                </ListLink>
                {
                    user.role !== 'viewer'
                    ?   
                        <>
                            <ListLink onClick={() => setPage('addUser')}>
                                <GroupAddIcon fontSize='inherit' />Adicionar usuários
                            </ListLink>
                            <ListLink onClick={() => setPage('manageUser')}>
                                <PeopleIcon fontSize='inherit' />Gerenciar usuários
                            </ListLink>
                        </>
                    : null
                }
            </UnorderedList>
        </AsideContainer>
    );
}

export default SettingsAside;