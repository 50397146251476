import Cookies from 'js-cookie';

var baseURL = ''

if(process.env.REACT_APP_MODE === 'dev'){
    baseURL = `http://localhost:8080/backend/`
}else{
    baseURL = `https://back-dot-${process.env.REACT_APP_PROJECT_ID}.appspot.com/backend/`
}

const api = async (endpoint, ...params) => {
    const pre_request = await fetch(`${baseURL}${endpoint}`, ...params);
    if(pre_request.status === 401 && pre_request.url.split('/')[4] !== 'login'){
        Cookies.remove('@user');
        window.location.href = '/';
    }
    return pre_request;
}

export default api;