import React from 'react';
import Lottie from 'lottie-react-web';
import lockerAnimation from '../../static/lottie/lockerAnimation.json';

import { LockerAnimationContainer } from './styles';

function LockerAnimation() {
  return(
    <LockerAnimationContainer>
      <Lottie 
        options={{
          animationData: lockerAnimation,
        }}
      />
    </LockerAnimationContainer>
  );
}

export default LockerAnimation;