import React, { useState, useContext } from 'react';
import UserInformation from '../UserInformation';
import EditUserInformation from '../EditUserInformation';

import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

import { ProfileContainer, PageTitle } from './styles';


function ProfileSettings() {

    const [ editMode, setEditMode ] = useState(false);
    const { user } = useContext(GeneralContext);

    return(
        <ProfileContainer>
            <PageTitle>Perfil</PageTitle>
            {!!editMode ? <EditUserInformation user={user}/> : <UserInformation user={user} editMode={setEditMode} />}
        </ProfileContainer>
    );
}

export default ProfileSettings;