import React from 'react';

import { ButtonContainer, ButtonObject } from './styles';

function Button({ children, width, margin, ...rest }) {
  return(
    <ButtonContainer style={{ margin, width }}>
        <ButtonObject style={{ width }} {...rest}>
            {children}
        </ButtonObject>
    </ButtonContainer>
  );
}

export default Button;