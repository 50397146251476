import React from 'react';
import EditIcon from '@material-ui/icons/Edit';

import { ProfileInformation, ProfilePhoto, ProfilePhotoContainer, InformationContainer, InformationTitle, InformationSection, ProfilePhotoHolder, ChangeContainer, ChangeHolder, ChangeButton } from './styles';

function UserInformation({ editMode, user}) {
    return(
        <ProfileInformation>
            <ProfilePhotoContainer>
                <ProfilePhotoHolder>
                    <ProfilePhoto src={user.photoURL}/>
                </ProfilePhotoHolder>
            </ProfilePhotoContainer>
            <InformationContainer>
                <InformationSection>
                    <InformationTitle>NOME DE USUÁRIO</InformationTitle>
                    <p>{user.displayName}</p>
                </InformationSection>
                <InformationSection>
                    <InformationTitle>EMAIL</InformationTitle>
                    <p>{user.email}</p>
                </InformationSection>
            </InformationContainer>
            <ChangeContainer>
                <ChangeHolder>
                    <ChangeButton onClick={() => editMode(true)}><EditIcon /></ChangeButton>
                </ChangeHolder>
            </ChangeContainer>
        </ProfileInformation>
    );
}

export default UserInformation;