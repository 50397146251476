import styled from 'styled-components';

export const TutorialContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TutorialBackground = styled.div`
    width: 100%;
    height: 100%;
    background: black;
    opacity: .8;
    position: absolute;
`;

export const VideoHolder = styled.div`
    width: 90%;
    height: 80%;
    max-width: 1280px;
    max-height: 720px;
    position: relative;
    z-index: 11;
`;

export const Video = styled.video`
    width: 100%;
    height: 100%;
`;

export const VideoHeader = styled.div`
    width: 100%;
    padding: 10px;
    position: relative;
    color: white;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const GobackHolder = styled.div`
    width: 10rem;
    label{
        cursor: pointer;
    }
`;
