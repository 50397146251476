import styled from "styled-components";

export const HomeContainer = styled.div`
    width: 90%;
    height: 100%;
    overflow: auto;
    padding: 8rem 2rem;
`;

export const WelcomeUser = styled.p`
    font-weight: 300;
    font-size: 2.5rem;
`;

export const UserTextHolder = styled.div`
    width: 100%;
    height: 100%;
    padding: 1rem;
`;

export const UserParagraph = styled.p`
    font-size: 1.5rem;
    text-indent: 3rem;
    text-align: justify;
`;

export const UserOrderedList = styled.ul`
    ul{
        margin-left: 2rem;
    }
`;

export const UserOrderedListItem = styled.li`

`;

export const PageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 5rem;
`;

