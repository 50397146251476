import React, { useContext } from 'react';
import Chart from "react-apexcharts";

import { ThemeContext } from 'styled-components';

import { BarContainer } from './styles';

function BarChart({ name, dados }) {

    const { primary } = useContext(ThemeContext);

    return(
        <BarContainer>
            <Chart
                options={{
                    chart: {
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: !!dados ? Object.keys(dados) : [],
                        labels: {
                            style: {
                                colors: primary.text
                            }
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: primary.text
                            }
                        },
                    },
                    title: {
                        text: name,
                        align: 'left',
                        style:{
                            fontFamily: 'Roboto',
                            color: primary.text,
                        }
                    },
                    legend:{
                        show: false
                    },
                    grid: {
                        borderColor: "#e0e0e01d"
                    }
                }}
                series={[ { name: "Infrações", data: !!dados ? Object.values(dados) : [] } ]}
                type="bar"
            />
        </BarContainer>
    );
}

export default BarChart;