import styled from 'styled-components';

export const SettingsContainer = styled.div`
    width: 90%;
    height: 100%;
    overflow: auto;
    display: flex;
`;

export const PageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
`;

