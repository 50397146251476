import styled from "styled-components";

export const TableContainer = styled.div`
`;

export const TableObject = styled.table`
    width: 100%;
    border-collapse: collapse;
    background: ${props => props.theme.primary.primary};
    box-shadow: 0 3px 5px #0000001d;
`;

export const TableBody = styled.tbody`
    tr:nth-child(even){
        background: ${props => props.theme.primary.complementary}1d;
    }
`;  
export const TableHeader = styled.thead`
    th:nth-child(1){    
        border-top-left-radius: 5px;
    }
    th:nth-last-child(1){    
        border-top-right-radius: 5px;
    }
`;
export const TableRow = styled.tr`
`;
export const TableData = styled.td`
    padding: 1rem;
    font-size: 1.2rem;
    span{
        display: inline-block;
        width: 20rem;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
`;
export const TableHead = styled.th`
    text-align: left;
    padding: 1rem;
    font-size: 1.5rem;
    color: ${props => props.theme.primary.text};
    background: ${props => props.theme.primary.complementary};
`;

export const TableSettings = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
`;

export const TableSettingsActions = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
`;

export const TableDataContentHolder = styled.div`
    display: flex;
`;

