import styled from "styled-components";

export const RoundedButtonContainer = styled.div`
`;

export const RoundedButton = styled.button`
    width: 30px;
    height: 30px;
    border: none;
    color: ${props => props.theme.primary.text};
    border-radius: 50%;
    background: ${props => props.theme.primary.complementary}; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .2rem;
    cursor: pointer;
    transition: 200ms ease;

    :hover{
        background: ${props => props.theme.primary.buttons.hover};
    }

    :disabled{
        background: ${props => props.theme.primary.complementary}1d;
        cursor: default;
    }
`;