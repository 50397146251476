import React, { useRef, useContext } from 'react';
import Input from '../ProfileInput';
import { Form } from '@unform/web';
import { useSnackbar } from 'notistack';
import RoundedButton from '../TableRoundedButton';
import SaveIcon from '@material-ui/icons/Save';
import api from '../../services/api';
import Cookies from 'js-cookie';
import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

import { ProfileInformation, ProfilePhotoContainer, InformationContainer, InformationTitle, InformationSection, ChangeContainer, ChangeHolder } from './styles';
import ImageInput from '../ImageInput';

function EditUserInformation({ user }) {

    const formRef = useRef(null);

    const token = Cookies.get('@user');

    const { setLoading } = useContext(GeneralContext);
    const { enqueueSnackbar } = useSnackbar();

    const handle_submit = async (payload) => {

        setLoading(true);

        let formData = new FormData();
        formData.append('display_name', payload.user)
        formData.append('email', payload.email)
        formData.append('photo_url', payload.avatar)

        api('user', {
            method: 'PUT',
            body: formData,
            headers: { 
                Authorization: `Bearer ${token}`,
            }
        }).then(async res => {
            if(res.status >= 200 && res.status < 300){
                setLoading(false);
                enqueueSnackbar('Alterado com sucesso!', { variant: "success" })
            }else{
                setLoading(false);
                enqueueSnackbar('Houve um erro para alterar!', { variant: "error" })
            }
        });

    }

    const submit_form = () => {
        formRef.current.submitForm();
    }

    return(
        <ProfileInformation>
            <Form onSubmit={handle_submit} ref={formRef}>
                <ProfilePhotoContainer>
                    <ImageInput name='avatar' image={user.photoURL}/>
                </ProfilePhotoContainer>
                <InformationContainer>
                    <InformationSection>
                        <InformationTitle>NOME DE USUÁRIO</InformationTitle>
                        <Input name='user' defaultValue={user.displayName} />
                    </InformationSection>
                    <InformationSection>
                        <InformationTitle>EMAIL</InformationTitle>
                        <Input name='email' defaultValue={user.email} />
                    </InformationSection>
                </InformationContainer>
            </Form>
            <ChangeContainer>
                <ChangeHolder>
                    <RoundedButton onClick={submit_form}><SaveIcon /></RoundedButton>
                </ChangeHolder>
            </ChangeContainer>
        </ProfileInformation>
    );
}

export default EditUserInformation;