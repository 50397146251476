import styled from "styled-components";

export const InputContainer = styled.div`
    input{
        background: ${props => props.theme.primary.primary};
        border-radius: 30px;
        padding: 5px 10px;
        border: 1px solid #3333331d;
        position: relative;
        z-index: 1;
        left: 35px;
        width: 0px;
        transition: 200ms ease-in;
    }
`;