import styled from 'styled-components';

export const DateContainer = styled.div``;

export const CalendarHolder = styled.div`
    position: absolute;
    z-index: 9999;
    width: 35rem;
    margin: .5rem 0  0 1rem;
    .rdrCalendarWrapper, .rdrDateRangeWrapper{width: 100%;}
`;

export const ActionHolder = styled.div`
    width: 100%;
    background: #EFF2F7;
    padding: 10px;
`;