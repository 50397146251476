import styled from "styled-components";

export const ProfileContainer = styled.div`
    width: 100%;
    padding: 20px;
`;

export const PageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
`;
