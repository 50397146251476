import styled from 'styled-components';

export const ButtonContainer = styled.div`
    
`;

export const ButtonObject = styled.button`
    background: ${props => props.theme.primary.complementary};
    border-radius: 30px;
    border: none;
    padding: 5px 10px;
    color: ${props => props.theme.primary.text};
    font-size: 1.5em;
    cursor: pointer;
    position: relative;
    z-index: 3;
    transition: 200ms ease;
    
    :hover{
        background: ${props => props.theme.primary.buttons.hover};
    }
`;