import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import LeftArrow from '@material-ui/icons/ArrowLeft';
import RightArrow from '@material-ui/icons/ArrowRight';

import { TableContainer, TableObject, TableHeader, TableRow, TableHead, TableBody, TableSettings } from './styles';

function ReportTable({ tableHeader, TableRows, page, setPage, results }) {
    return(
        <TableContainer>
            <TableSettings>
                <IconButton onClick={() => setPage(page > 1 ? page - 1 : page)}><LeftArrow /></IconButton>
                {page}
                <IconButton onClick={() => setPage(page + 1 <= Math.ceil(results / 10) ? page + 1 : page)}><RightArrow /></IconButton>
                Resultados: { results }
            </TableSettings>
            <TableObject>
                <TableHeader>
                    <TableRow>
                        {
                            !!tableHeader && tableHeader.length > 0
                            ? tableHeader.map((item, index) => (
                                <TableHead key={index}>{item}</TableHead>
                            ))
                            : null
                        }
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <TableRows />
                </TableBody>
            </TableObject>
        </TableContainer>
    );
}

export default ReportTable;