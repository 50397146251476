import styled from 'styled-components';

export const AddContainer = styled.div`
    position: relative;
`;

export const AddHolder = styled.div`
    width: 536px;
    height: 304px;
    background: ${props => props.theme.primary.primary};
    box-shadow: 0 2px 5px #0000001d;
    margin-top: 1rem;
    right: 3rem;
    position: absolute;
    padding: 10px;
    border-radius: 10px;
`;

export const AddSectionTitle = styled.h2`
    font-weight: 400;
    font-size: 1.8rem;
`;

export const AddSectionHeader = styled.div`
    margin-bottom: 1rem;
`;

export const AddBoxButtonsHolder = styled.div`
    display: flex;
    justify-content: center;
`;