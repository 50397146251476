import React, { useEffect, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';

import { KeywordsContainer, PageTitle } from './styles';
import Table from '../../components/KeywordTable';

//services
import api from '../../services/api';
import GeneralContext from '../../contexts/GeneralContext/GeneralContext';

function Keywords() {

    const [ tableRows, setTableRows ] = useState([])
    const [ totalPages, setTotalPages ] = useState(0)
    const [ page, setPage ] = useState(1)
    const [ refresh, setRefresh ] = useState(0)
    const [ query, setQuery ] = useState('')

    
    const { enqueueSnackbar } = useSnackbar();
    const { setLoading, user } = useContext(GeneralContext);
    
    const token = Cookies.get('@user');

    const tableHeader = user.role !== 'viewer' ? [ 'Índice', 'Palavra', 'Ações' ] : [ 'Índice', 'Palavra' ]

    useEffect(() => {
        setLoading(true);

        api(`terms?page=${page}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}`}
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                setTableRows(data.rows)
                setTotalPages(data.totalPages)
                setLoading(false);
            }else{
                setLoading(false);
            }
        });

    }, [page, setLoading, token, refresh])

    useEffect(() => {
        let filtered = tableRows.filter(item => item.toLowerCase().includes(query.toLowerCase()))
        
        if(filtered.length === 0 && tableRows.length > 0){
            api(`search/terms?query=${query}`, {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}`}
            }).then(async res => {
                const data = await res.json()
                if(res.status >= 200 && res.status < 300){
                    setTableRows([data.term])
                }else{
                    enqueueSnackbar(data.response, { variant: 'error' });
                }
            });
        }else{
            setTableRows(filtered);
        }

    }, [ query ])

    const handle_delete = async (term) => {
        api(`terms`, {
            method: 'DELETE',
            body: JSON.stringify({ term }),
            headers: { 
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                enqueueSnackbar(data.response, { variant: 'success' });
                setRefresh(Math.random())
            }else{
                enqueueSnackbar(data.response, { variant: 'error' });
            }
        });
    }

    return(
        <KeywordsContainer>
            <PageTitle>Palavras-chave</PageTitle>
            <Table 
                tableHeader={tableHeader} 
                tableRows={tableRows} 
                totalPages={totalPages} 
                page={page} 
                setPage={setPage} 
                setRefresh={setRefresh}
                handle_delete={handle_delete}
                setQuery={setQuery}
            />
        </KeywordsContainer>
    );
}

export default Keywords;