import styled from "styled-components";

export const AsideContainer = styled.aside`
    width: 20%;
    max-width: 240px;
    height: 100%;
    background: ${props => props.theme.primary.primary};
    box-shadow: 0 3px 6px #0000001d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const AsideUnorderedList = styled.ul`
    width: 100%;
    style-type: none;
    
    a{
        color: inherit;
        text-decoration: none;
    }
`;

export const AsideLink = styled.li`
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    border-left: 2px solid transparent;
    cursor: pointer;
    transition: 100ms ease-in;
    border-bottom: 1px solid ${props => props.theme.primary.text}0d;

    :hover{
        border-left: 2px solid ${props => props.theme.primary.complementary};
        background: ${props => props.theme.primary.hover};
    }

    button{
        font-size: 15px;
    }

    svg{
        margin-right: 3rem;
    }
    
`;

export const AsideUnorderedListHeader = styled.div`
    width: 100%;
`;

export const PhotoHolder = styled.div`
    width: 50px;
    height: 50px;
    border: 1px solid black;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
`;

export const WelcomeHolder = styled.div`
    width: 80%;
    font-size: 1.5rem;

    p:nth-child(1){
        opacity: .5;
        font-size: 10px;
    }
`;

export const AlestLogoHolder = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    padding: 20px 0 0 0;
`;

export const UserHolder = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    background: ${props => props.theme.primary.secundary};
    padding: 10px;
`;