import React from 'react';

import { CardContainer } from './styles';

function Card({ width, children }) {
    return(
        <CardContainer width={width}>
            {children}
        </CardContainer>
    );
}

export default Card;