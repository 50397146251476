import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Form } from '@unform/web';
import Input from '../LoginInput';
import Button from '../Button';
import Cookie from 'js-cookie';
import api from '../../services/api';

import { LoginContainer, LoginTitle } from './styles';
import GeneralContext from '../../contexts/GeneralContext/GeneralContext';
import AuthContext from '../../contexts/AuthContext/AuthContext';

function LoginBox() {

    const { setLoading } = useContext(GeneralContext);
    const { auth, setAuth } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar()

    const history = useHistory()

    useEffect(() => {
        if(auth === true) history.push('/home');
    }, [ auth ])

    const handle_login = ({ username, password }) => {

        setLoading(true);

        api('login', { 
            method: 'GET',
            headers: { user: JSON.stringify({ username, password }) }
        }).then(async (res) => {
            if(res.status >= 200 && res.status < 300){
                const data = await res.json()
                Cookie.set('@user', data.response.user.stsTokenManager.accessToken, { expires: new Date(data.response.user.stsTokenManager.expirationTime) });
                setLoading(false);
                setAuth(true);
            }else{
                setLoading(false);
                enqueueSnackbar("Usuário ou senha incorreto.", { variant: "error" });
            }
        })

    }

    return(
        <LoginContainer>
            <LoginTitle>
                Login
            </LoginTitle>
            <Form onSubmit={handle_login}>
                <Input name='username' type="text" placeholder='Usuário' width={'100%'}/>
                <Input name='password' type="password" placeholder='Senha' width={'100%'} />
                <Button width={'100%'} margin='5rem 0px 0px 0px'>
                    Entrar
                </Button>
            </Form>
        </LoginContainer>
    );
}

export default LoginBox;