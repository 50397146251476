import styled from 'styled-components';

export const ReportContainer = styled.div`
    width: 90%;
    height: 100%;
    padding: 8rem 2rem;
    overflow: auto;
`;

export const PageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
`;

export const ReportHolder = styled.div`
    width: 100%;
`;

export const ChartHolder = styled.div`

`;

export const ChartRow = styled.div`
    display: flex;
    flex-wrap: no-wrap;
`;

export const ReportHeader = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    margin-bottom: 1rem;
`;

export const TableRow = styled.tr`
`;

export const TableData = styled.td`
    padding: 1rem;
    font-size: 1.2rem;
    span{
        padding: 1rem;
        font-size: 1.2rem;
        display: inline-block;
        width: 20rem;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
`;

export const CardTitle = styled.div`
    padding: .5rem;
    font-size: 1.2rem;
`;

export const CardContent = styled.div`
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    font-size: 5rem;
    transition: transform 200ms ease;

    :hover{
        transform: translateY(-10%);
        cursor: default;
    }
`;