import React from 'react';
import Loader from '../../components/Loader';
import Aside from '../../components/Aside';

import { PanelContainer } from './styles';
import GlobalStyle from '../../static/global_css';

function Panel({ Content }) {
  return(
    <PanelContainer>
      <GlobalStyle />
      <Loader />
      <Aside />
      <Content />
    </PanelContainer>
  );
}

export default Panel;